<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <div class="router-view-container">
        <section class="flex items-center space-x-4 mb-5">
          <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
            <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
          </vs-button>
          <h1 class="page_title">{{ $route.meta.title }}</h1>
        </section>

        <main v-if="is_API_loaded || isNewMembership" class="space-y-5">
          <!-- 如果是新增會員等級，就隱藏此區塊 -->
          <!-- <h1 v-if="!isNewMembership" class="text-xl font-medium text-gray-text_dark">{{ title }}</h1> -->

          <!-- 新增會員等級後顯示 -->
          <transition name="fade">
            <section v-if="alertAfterCreated">
              <vs-alert color="success" gradient>
                <template #icon>
                  <svg-icon icon-class="success" className="xxl"></svg-icon>
                </template>
                <template #title> {{ alertTitle }} 已新增成功 </template>
              </vs-alert>
            </section>
          </transition>

          <!-- 2. -->
          <section class="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-4">
            <!-- 2.1.1 -->
            <div class="w-full md:w-14/20 space-y-4 flex-shrink-0">
              <article class="box">
                <div>
                  <h1 class="title">會員等級名稱 *</h1>
                  <ValidationProvider
                    mode="aggressive"
                    tag="div"
                    class="relative"
                    name="會員等級名稱"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <vs-input v-model="title"></vs-input>

                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
                <div>
                  <h1 class="title">總累計消費升級門檻 *</h1>
                  <ValidationProvider
                    mode="aggressive"
                    tag="div"
                    class="relative"
                    name="總累計消費升級門檻"
                    rules="required|numeric|min_value:1"
                    v-slot="{ errors }"
                  >
                    <div class="flex items-center border border-gray-border rounded-xl pl-3 space-x-1 overflow-hidden h-10">
                      <div>
                        <span>NT$</span>
                      </div>
                      <input type="number" class="w-full h-full" v-model.number="pays" />
                    </div>
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
                <div>
                  <h1 class="title">會員折扣</h1>
                  <ValidationProvider
                    mode="aggressive"
                    tag="div"
                    class="relative"
                    name="會員折扣"
                    rules="numeric|min_value:1|max_value:99"
                    v-slot="{ errors }"
                  >
                    <div class="flex items-center border border-gray-border rounded-xl px-3 overflow-hidden h-10">
                      <input type="number" class="w-full h-full" v-model.number="discount" />
                      <div>
                        <p class="w-14">% OFF</p>
                      </div>
                    </div>
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </article>
            </div>

            <!-- 2.2 -->
            <div class="flex-grow space-y-4">
              <div class="box">
                <h3 class="h3">摘要</h3>
                <div class="space-y-2">
                  <p class="list_item" v-if="pays">會員累計消費 NT${{ pays | currency }} 後自動升級</p>
                  <p class="list_item" v-if="discount">全館商品 {{ _discount }} 折</p>
                  <p class="list_item" v-else>不給予會員折扣</p>
                </div>
              </div>
            </div>
          </section>

          <hr />

          <section class="flex items-center justify-between">
            <vs-button @click="delete_dialog = true" v-if="!isNewMembership" size="large" color="danger" border>
              <span class="font-medium">刪除會員等級</span>
            </vs-button>
            <router-link v-else :to="{ name: 'memberships' }">
              <vs-button size="large" border>
                <span class="font-medium">取消</span>
              </vs-button>
            </router-link>

            <vs-button v-if="isNewMembership" size="large" ref="saveMembership" :disabled="invalid" @click="saveMembership(invalid)">
              <span class="font-medium">新增會員等級</span>
            </vs-button>
            <vs-button v-else size="large" ref="saveMembership" :disabled="invalid" @click="saveMembership(invalid)">
              <span class="font-medium">儲存會員等級</span>
            </vs-button>
          </section>
        </main>
      </div>
    </ValidationObserver>

    <!-- 骨架屏 -->
    <main v-if="!is_API_loaded && !isNewMembership" class="router-view-container space-y-4">
      <section class="flex items-center space-x-4 justify-between">
        <skeleton width="48px" />

        <div v-if="!isNewMembership" class="flex lg:w-full items-center justify-between">
          <skeleton class="hidden lg:block" width="50%" height="40px" />
          <div class="flex items-center space-x-2">
            <skeleton width="82px" />
            <skeleton width="82px" />
          </div>
        </div>
      </section>
      <skeleton v-if="!isNewMembership" class="lg:hidden" width="80%" height="40px" />

      <section class="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-4">
        <div class="w-full md:w-14/20 space-y-4">
          <article class="box">
            <skeleton />

            <div>
              <skeleton height="200px" />
            </div>
          </article>

          <article class="box">
            <skeleton />
          </article>
        </div>

        <div class="flex-grow space-y-4">
          <div class="box">
            <skeleton />
            <skeleton />
            <skeleton />
          </div>
          <div class="box">
            <skeleton />
            <skeleton />
            <skeleton />
          </div>
        </div>
      </section>

      <hr />

      <section class="flex items-center justify-between">
        <skeleton width="82px" />
        <skeleton width="82px" />
      </section>
    </main>

    <!-- 刪除會員等級 dialog -->
    <vs-dialog overflow-hidden blur v-model="delete_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除此會員等級？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除的會員等級無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="delete_dialog = false">取消</vs-button>
          <vs-button @click="deleteMembership" size="large" transparent color="danger" ref="deleteMembershipBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
export default {
  name: 'membership',
  data() {
    return {
      is_API_loaded: false,
      isNewMembership: false,
      alertTitle: '',
      alertAfterCreated: false,
      delete_dialog: false,
      title: '',
      pays: '',
      discount: '',
    }
  },
  computed: {
    _discount() {
      return (100 - this.discount) / 10
    },
  },
  watch: {
    $route(to, from) {
      this.isNewMembershipFunc()
    },
  },
  created() {
    this.isNewMembershipFunc()
  },
  methods: {
    isNewMembershipFunc() {
      // 判斷是新增部落格 還是 編輯部落格
      this.$route.name === 'new-membership' ? (this.isNewMembership = true) : (this.isNewMembership = false)

      if (!this.isNewMembership) this.loadMembership()
    },
    // 3.10.2.2 會員等級資料-讀取
    loadMembership() {
      this.$axios({
        url: 'front/store/mvip/getMVipUnit.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          smvno: this.$route.params.id,
        },
      }).then((res) => {
        if (res.data.Success) {
          let data = res.data.Data
          this.title = data.title
          this.pays = data.pays
          this.discount = data.discount ? data.discount : ''
          this.is_API_loaded = true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 3.10.2.3 會員等級資料-新增
    // 3.10.2.4 會員等級資料-修改
    saveMembership(invalid) {
      this.alertAfterCreated = false
      // 如果驗證未通過，return
      if (invalid) return

      const loading = this.$vs.loading({
        target: this.$refs.saveMembership,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      let url = ''

      let data = {
        lang: 0,
        title: this.title,
        pays: this.pays,
        discount: this.discount ? this.discount : 0,
      }

      if (this.isNewMembership) {
        url = 'front/store/mvip/setMVipData.php'
      } else {
        url = 'front/store/mvip/uMVipData.php'
        data.smvno = this.$route.params.id
      }

      this.$axios({
        url: url,
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: data,
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()

          this.$myVS.openNoti(res.data.Message, '', undefined)

          // 若是新增
          if (this.isNewMembership) {
            this.alertAfterCreated = true // 呈現alert
            this.alertTitle = this.title
            // this.$router.replace({
            //   // 跳轉到新頁面
            //   name: 'edit-membership',
            //   params: {
            //     id: res.data.Data.smvno,
            //   },
            // })
            this.$router.replace({
              name: 'memberships',
            })
          } else {
            // this.loadMembership()
            this.$router.replace({
              name: 'memberships',
            })
          }
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 3.10.2.5 會員等級資料-刪除
    deleteMembership() {
      const loading = this.$vs.loading({
        target: this.$refs.deleteMembershipBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/mvip/dMVipData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          smvno: this.$route.params.id,
        },
      }).then((res) => {
        if (res.data.Success) {
          loading.close() // 關閉按鈕loading
          this.delete_dialog = false // 關閉dialog
          this.$myVS.openNoti(res.data.Message, '', undefined)

          this.$router.replace({
            name: 'memberships',
          })
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close() // 關閉按鈕loading
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.box {
  @apply flex-grow h-fit bg-white p-4 rounded-xl space-y-4;
}

.title {
  @apply text-sm text-gray-text_dark mb-1;
}

.list_item {
  @apply ml-0 relative px-4;

  &::before {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
    background: var(--gray-border);
  }
}

::v-deep .vs-alert__title {
  height: 100%;
}

::v-deep .vs-alert__content {
  min-height: 0px;
}
</style>
